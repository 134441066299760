<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div
			class="flex-1 bg-white pa-24 radius-20"
		>
			<div>
				<div class="under-line-bbb pb-16 flex-row">
					<h3 class="flex-1 size-px-20">{{ program.title }}</h3>
				</div>
				<PaymentInfo
					:user="user"
					:item="item"
					class="mt-50 "

					@onLoad="setPointInfo"
				>
				</PaymentInfo>

				<div
					class="pa-10-20"
				>
					<div class="justify-space-between ">
					</div>
					<div class="mt-20 justify-space-between items-center">
						<DatePicker type="year" :date="search_year" @click="setDate($event)" ></DatePicker>
						<div
							class="justify-space-between"
						>
							<div>
								<button
									class="btn-inline  mr-10 radius-20"
									:class="is_charge ? 'btn-primary' : 'btn-primary-outline'"
									@click="to('+')"
								>적립내역</button>
								<button
									class="btn-inline radius-20"
									:class="is_use ? 'btn-primary' : 'btn-primary-outline'"
									@click="to('-')"
								>사용내역</button>
							</div>
						</div>
					</div>

					<div
						class="mt-20"
					>
						<table
							v-if="items_list.length > 0"
							class="table table-odd size-px-14"
						>
							<thead>
								<tr>
									<th>불렛</th>
									<th>구분</th>
									<th>일시</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(history, h_index) in items_list"
									:key="'history_' + h_index"
									class="under-line-not-last pb-20 mb-20"
								>
									<td>
										<div
											class="font-weight-bold size-px-16"
											:class="history.type == '+' ? 'color-blue-3e' : ' color-red-dc'"
										>{{ history.type}}{{ history.accumulate_point }}</div>
									</td>
									<td>
										<div class="flex-row gap-5 justify-center color-333">
											<div>{{ history.payment_type_code_name }}</div>
											<span>/</span>
											<div> {{ history.point_accumulate_desctn }}</div>
										</div>
									</td>
									<td>
										<div class="color-333">{{ history.point_accumulatedate }}</div>
									</td>
								</tr>
							</tbody>
						</table>

						<Empty
							v-else
							class="top-line"
							:text="text"
						>
						</Empty>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import PaymentInfo from "@/view/Payment/PointInfo";
import DatePicker from "@/components/DatePicker";
import MypageSide from "@/view/Mypage/mypage-lnb";
import Empty from "@/view/Layout/Empty";
export default {
	name: 'PaymentList'
	, components: {Empty, MypageSide, DatePicker, PaymentInfo}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'payment list'
				, title: 'MY 불렛'
				, not_footer: true
				, type: 'mypage'
				, is_save: true
				, callBack: {
					name: 'to_payment_history'
					, title: '결제내역'
				}
				, bg_contents: 'bg-gray01'
			}
			, item: {
				point: 0
			}
			, items: [
				{ point: 100, type: '+', type_name: '충전', pay_type: 'card', pay_type_name: '신용카드', wDate: '2023.06.01 18:20:11'}
				, { point: 100, type: '+', type_name: '이벤트', pay_type: 'card', pay_type_name: '오픈 이벤트', wDate: '2023.06.01 18:20:11'}
				, { point: 100, type: '-', type_name: '구독', pay_type: 'card', pay_type_name: '플랜 구독', wDate: '2023.06.01 18:20:11'}
				, { point: 100, type: '-', type_name: '후원', pay_type: 'card', pay_type_name: '카르텔 후원', wDate: '2023.06.01 18:20:11'}
				, { point: 100, type: '+', type_name: '충전', pay_type: 'card', pay_type_name: '인앱', wDate: '2023.06.01 18:20:11'}
				, { point: 100, type: '+', type_name: '충전', pay_type: 'card', pay_type_name: '신용카드', wDate: '2023.06.01 18:20:11'}
			]
			, items_use: [

			]
			, item_payment: {

			}
			, is_on_payment: false
			, type: this.$route.params.type ? this.$route.params.type : '+'
			, search_year: ''
			, item_point: {

			}
			, text: '내역이 없습니다.'
		}
	}
	, computed: {
		is_charge: function(){
			let t = false
			if(this.type != '-'){
				t = true
			}
			return t
		}
		, is_use: function(){
			let t = false
			if(this.type == '-'){
				t = true
			}
			return t
		}
		, items_list: function(){
			let t = this.items
			if(this.type == '-'){
				t = this.items_use
			}
			return t.filter((item) => {

				if(item.point_accumulate_code == 'PT00300001'){
					item.type = '+'
					item.point_accumulate_desctn = item.point_accumulate_desctn ? item.point_accumulate_desctn : '-'
				}else{
					item.type = '-'
					item.accumulate_point = item.use_point
					item.payment_type_code_name = item.point_use_code_name
					item.point_accumulatedate = item.point_useday
					item.point_accumulate_desctn = item.point_use_desctn ? item.point_use_desctn : '-'
				}
				return item

			})
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_user_point_history
					, data: {
						member_number: this.user.member_number
						, point_number: this.item_point.point_number
						, search_year: this.search_year + ''
					}
					, type: true
				})

				if(result.success){
					this.items = result.data.point_accumulate_particulars_list
					this.items_use = result.data.point_use_particulars_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postPayment: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_request_list
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPayment: function(item){
			this.is_on_payment = true
			this.item_payment = item
		}
		, offPayment: function(){
			this.is_on_payment = false
			this.item_payment = {}
		}
		, to: function(type){
			this.type = type
			// this.$bus.$emit('to', { name: 'PointHistory', params: { type: type}})
		}
		, setDate: function(date){
			this.search_year = date
			this.getData()
		}
		, setPointInfo: function(item){
			this.item_point = item
			this.getData()
		}
	}
	, created() {
		this.$emit('onLoad', this.program)

		this.$bus.$off('callBack')
		this.$bus.$on('callBack', (call) => {
			if(call.name == 'to_payment_history'){
				this.$emit('to', { name: 'PaymentHistory'})
			}
		})
	}
}
</script>
<style scoped>
.table-odd tbody tr:nth-child(odd){background-color:unset;}
</style>